import React from "react";
import { HiOutlineBadgeCheck } from "react-icons/hi";

export const AuthorSpan = () => {
  return (
    <span className="flex items-center gap-1 justify-start">
      {" "}
      <img
        src="https://www.picsum.photos/50/50"
        className="rounded-full w-5 h-5"
      />
      <p className="text-secondary font-bold text-sm">Apologetica Author</p>
      <HiOutlineBadgeCheck className="text-tertiary" size={20} />
    </span>
  );
};

import React from "react";
import {
  FaBible,
  FaBook,
  FaHandSparkles,
  FaStarAndCrescent,
} from "react-icons/fa";
import { PiCross } from "react-icons/pi";
import {
  GiBookmarklet,
  GiClassicalKnowledge,
  GiMaterialsScience,
} from "react-icons/gi";
import { GoLaw } from "react-icons/go";
import { HiAcademicCap } from "react-icons/hi";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { collection, doc, updateDoc } from "firebase/firestore";
import DynamicIconLoader from "./CMS/new_icon_picker/DynamicIconLoader";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "./CMS/logic/HelperFunctions";

export const Categories = (props) => {
  // Upload this list of categories to the database under the managedCats key in the metadata doc in the metadata collection

  return (
    <div className=" mx-auto max-w-4xl p-8 flex flex-col gap-8 justify-around ">
      <h3 className="text-4xl font-bold text-center text-gray-700">
        Categoriën
      </h3>
      <div className="flex flex-wrap justify-around gap-6 ">
        {props.metadata?.managedCats.map((category) => {
          return (
            <Link
              key={category.title}
              to={"/" + encodeURI(category.title?.replaceAll(" ", "-"))}
            >
              {" "}
              <div className="bg-primary cursor-pointer overflow-x-hidden transition-all duration-200 group flex justify-between items-center text-xl text-slate-50 font-bold rounded h-20 w-64">
                <div className="flex items-center text-slate-50 fill-slate-50 transition-all duration-200 justify-center text-center w-1/3">
                  <DynamicIconLoader iconName={category.icon} />
                </div>
                <div className="border-l flex flex-col justify-center items-center px-1 py-2 w-2/3">
                  <h5 className="text-center">
                    {capitalizeFirstLetter(
                      category.title?.replaceAll("-", " ")
                    )}
                  </h5>
                  <p className="font-medium text-center text-sm">
                    {category.subtitle}
                  </p>
                </div>{" "}
                <p className="-mr-32 ml-32 transition-all opacity-100 group-hover opacity-100 duration-200 group-hover:mr-4 group-hover:ml-0">
                  <BsFillArrowRightCircleFill size={24} />
                </p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

import React from "react";
import { PiCross } from "react-icons/pi";
import { FaBible } from "react-icons/fa";
import { HoverCard, Tooltip } from "@mantine/core";

export const CategoryIconsBar = () => {
  return (
    <div className=" p-2 flex gap-2 items-center">
      <HoverCard width={280}>
        <HoverCard.Target>
          <div className=" bg-primary rounded-full w-8 h-8 flex items-center justify-center text-slate-50">
            <PiCross size={20} />
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <span className="inline break-word gap-1">
            <div className="flex items-center gap-2">
              <div className="inline bg-primary rounded-full w-8 h-8 flex items-center justify-center text-slate-50">
                <PiCross className="inline" size={20} />
              </div>
              <p className="inline font-bold">Jezus</p>
            </div>
            <p className="inline">
              Dit artikel behoort tot de categorie Jezus. Dit zijn artikelen
              over onze redder.
            </p>
          </span>
        </HoverCard.Dropdown>
      </HoverCard>
      <HoverCard width={280}>
        <HoverCard.Target>
          <div className=" bg-quatrary rounded-full w-8 h-8 flex items-center justify-center text-slate-50">
            <FaBible size={20} />
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <span className="inline break-word gap-1">
            <div className="flex items-center gap-2">
              <div className="inline bg-quatrary rounded-full w-8 h-8 flex items-center justify-center text-slate-50">
                <FaBible className="inline" size={20} />
              </div>
              <p className="inline font-bold">Bijbel</p>
            </div>
            <p className="inline">
              Dit artikel behoort tot de categorie Bijbel. Dit zijn artikelen
              over de Bijbel.
            </p>
          </span>
        </HoverCard.Dropdown>
      </HoverCard>
    </div>
  );
};

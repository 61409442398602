import React, { useState, useEffect } from "react";
import { Loader } from "@mantine/core";

const DynamicIconLoader = (props) => {
  const [svgContent, setSvgContent] = useState("");

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + `/all_icons/${props.iconName}.svg`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        if (data.startsWith("<svg") && data.endsWith("</svg>")) {
          if (props.stops) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, "image/svg+xml");
            const svg = doc.querySelector("svg");

            // Create linearGradient element and stops
            const linearGradient = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "linearGradient"
            );
            linearGradient.setAttribute("id", "my-cool-gradient" + props.keys);
            linearGradient.setAttribute("x2", "1");
            linearGradient.setAttribute("y2", "1");

            props.stops.forEach((stopInfo) => {
              const stop = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "stop"
              );
              stop.setAttribute("offset", stopInfo.offset);
              stop.setAttribute("stop-color", stopInfo.color);
              linearGradient.appendChild(stop);
            });

            // Append the gradient to the SVG
            svg.insertBefore(linearGradient, svg.firstChild);

            // Apply the gradient to all path elements
            svg.querySelectorAll("path").forEach((path) => {
              path.setAttribute("fill", `url(#my-cool-gradient${props.keys})`);
            });

            // Serialize the SVG back to string
            const serializer = new XMLSerializer();
            const serializedSVG = serializer.serializeToString(svg);

            setSvgContent(serializedSVG);
          } else {
            setSvgContent(data);
          }
        } else {
          console.error("Fetched content is not an SVG.");
          setSvgContent(null);
        }
      })
      .catch((error) => console.error("Fetch error: ", error));
  }, [props.iconName, props.stops, props.key]);

  if (!svgContent) {
    return <Loader size={"sm"} />;
  }

  return (
    <div
      onClick={() => {
        if (props.onClick) props.onClick(props.iconName);
      }}
      className={props.className + " w-8 h-8 " || " w-8 h-8 "}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

export default DynamicIconLoader;

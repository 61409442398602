import React from "react";
import defense from "../geloofsverdediging.svg";
import { NavBar } from "../NavBar";
import { motion } from "framer-motion";
import { useViewportSize, useWindowScroll } from "@mantine/hooks";
import { Button } from "@mantine/core";
import { MainCard } from "../MainCard";
import { OtherCard } from "../OtherCard";
import { SmallCard } from "../SmallCard";
import { ArticleSection } from "../ArticleSection";
import { Categories } from "../Categories";
import usePostsSection from "../CMS/hooks/usePostsSection";
import { collection, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
const transition = { duration: 4, yoyo: Infinity, ease: "easeInOut" };

export const Home = (props) => {
  const highlightsQuery = collection(props.db, "docs");
  const HighlightPostsQuery = query(
    highlightsQuery,
    where("frontpageHighlight", "==", true)
  );
  const [highlistPosts] = useCollectionData(HighlightPostsQuery);

  const [scroll, scrollTo] = useWindowScroll();
  console.log(highlistPosts);

  return (
    <div className="w-screen">
      <div className=" w-screen -mt-12">
        <div className="w-full flex justify-between h-128">
          <div className="flex w-2/5 text-gray-700 flex-col z-10 justify-around p-8  -mr-40">
            <h1 className="text-4xl md:text-6xl lg:text-8xl break-word  w-full  font-bold ">
              Geloofs verdediging
            </h1>
            <h2 className="text-lg md:text-2xl lg:text-3xl font-bold">
              Liefhebben met heel uw hart, met heel uw ziel en met heel uw
              verstand
            </h2>
          </div>{" "}
          <img
            src={defense}
            className="w-3/5 md:w-4/5   object-cover"
            style={{
              clipPath: ` ellipse(${scroll.y / 25 + 90}% ${
                -scroll.y / 10 + 100
              }% at 100% 20%)`,
            }}
          />
        </div>
        <div className="px-8 text-gray-700 gap-8 py-8  flex flex-col">
          {" "}
          <h2 className="text-xl mx-auto md:text-3xl lg:text-4xl font-bold">
            Uitgelicht
          </h2>
          <div className="flex gap-8 flex-wrap justify-center items-center p-4 ">
            {highlistPosts?.map((post) => {
              return (
                <OtherCard
                  post={post}
                  imgClassNames={"h-80"}
                  hideAuthor={true}
                  hideCategories={true}
                  category={
                    props.category || post.categorylist
                      ? post.categorylist[0]
                      : "Nieuws"
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-32" />
      <ArticleSection db={props.db} metadata={props.metadata} />
      <Categories db={props.db} metadata={props.metadata} />
    </div>
  );
};

import React from "react";
import { AuthorSpan } from "./AuthorSpan";
import defense from "./geloofsverdediging.svg";
import CMSConfig from "./CMSConfig";
import { Link } from "react-router-dom";

export const SmallCard = (props) => {
  return (
    <Link
      to={`/${encodeURIComponent(
        props.category.replaceAll(" ", "-")
      )}/${encodeURIComponent(props.post.title.replaceAll(" ", "-"))}`}
    >
      <div className="flex flex gap-2 md:w-80 lg:w-96 shrink-0  group hover:-translate-y-1 transition-all duration-200 cursor-pointer rounded justify-start">
        <img
          className="rounded-md w-16 h-16  md:w-20 md:h-20 lg:w-32 lg:h-32  object-cover"
          src={props.post[CMSConfig.postImgVarname || "img"] || defense}
        ></img>
        <div>
          <h3 className="font-bold text-lg group-hover:underline line-clamp-2">
            {props.post.title}
          </h3>
          <AuthorSpan />
          <p className="text-gray-600 line-clamp-2">
            {props.post.clean_content}
          </p>
        </div>
      </div>
    </Link>
  );
};

import React from "react";
import { OtherCard } from "./OtherCard";
import { SmallCard } from "./SmallCard";
import usePostsSection from "./CMS/hooks/usePostsSection";

export const ArticleSection = (props) => {
  const { posts, pinnedPosts, pinnedPostsOverflow } = usePostsSection(props);

  console.log("posts", posts);

  return (
    <div className=" max-w-6xl mx-auto p-8 flex flex-col gap-8 justify-around ">
      <h3 className="text-4xl font-bold text-center text-gray-700">
        Recente artikelen
      </h3>
      <div className="flex flex-col  md:flex-row text-gray-700 justify-center gap-8 ">
        <div className="flex flex-col items-start lg:flex-row  gap-8 justify-center mx-auto">
          {pinnedPosts?.slice(0, 2).map((post) => {
            return (
              <OtherCard
                post={post}
                imgClassNames={"h-64 w-96"}
                category={
                  props.category || post.categorylist
                    ? post.categorylist[0]
                    : "Nieuws"
                }
              />
            );
          })}
        </div>
        <div className="flex flex-col  gap-4 justify-start">
          {posts?.slice(0, 3).map((post) => {
            return (
              <SmallCard
                post={post}
                category={
                  props.category || post.categorylist
                    ? post.categorylist[0]
                    : "Nieuws"
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

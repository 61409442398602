import React from "react";

export const BeautyButton = (props) => {
  return (
    <button
      className={`text-base ${
        !props.bgStyling &&
        " bg-gradient-to-br from-hard-yellow to-yellow-600 hover:bg-gradient-to-bl"
      } h-11 group flex gap-2 items-center justify-center py-2 px-4 font-bold text-white rounded-full shadow transition-all duration-500 ${
        props.addCss
      }  ${props.bgStyling}`}
      onClick={props.onClick}
    >
      {props.text}
      {!props.hideAnim && (
        <div className="flex ">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="text-white font-bold group-hover:translate-x-4  transition-all  "
            viewBox="0 0 16 16"
          >
            <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
          </svg>{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="text-white opacity-75 group-hover:opacity-90 font-bold -ml-3 group-hover:translate-x-8 transition-all "
            viewBox="0 0 16 16"
          >
            <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
          </svg>
        </div>
      )}
    </button>
  );
};

import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import CMSConfig from "../../CMSConfig.json";
import { dynamicSort } from "../logic/HelperFunctions";

export default (props) => {
  const [posts, setPosts] = useState();
  const [pinnedPosts, setPinnedPosts] = useState();
  const [pinnedPostsOverflow, setPinnedPostsOverflow] = useState(false);

  const [postsRef, setPostsRef] = useState();
  const [rawPosts] = useCollectionData(postsRef);

  const [pinnedRef, setPinnedRef] = useState();
  const [rawPinnedPosts] = useCollectionData(pinnedRef);

  const newToOld = true;

  useEffect(() => {
    console.log("raw", rawPosts);
  }, [rawPosts]);

  useEffect(() => {
    if (props.db) {
      let postsQuery;
      let pinnedQuery;

      if (props.category) {
        postsQuery = query(collection(props.db, String(props.category)));
        postsQuery = query(postsQuery, orderBy("date", "desc"));
        postsQuery = query(postsQuery, limit(4));

        pinnedQuery = query(collection(props.db, String(props.category)));
        pinnedQuery = query(pinnedQuery, orderBy("date", "desc"));
        pinnedQuery = query(pinnedQuery, limit(2));
      } else {
        postsQuery = query(collection(props.db, "docs"));
        postsQuery = query(postsQuery, orderBy("date", "desc"));
        postsQuery = query(postsQuery, limit(4));

        pinnedQuery = query(collection(props.db, "docs"));
        pinnedQuery = query(pinnedQuery, orderBy("date", "desc"));
        pinnedQuery = query(pinnedQuery, limit(2));
      }

      const loadPosts = async () => {
        const postsSnapshot = await getDocs(postsQuery);
        const pinnedSnapshot = await getDocs(pinnedQuery);

        const postsData = postsSnapshot.docs.map((doc) => doc.data());
        const pinnedData = pinnedSnapshot.docs.map((doc) => doc.data());

        console.log("posts", postsData);

        setPosts(postsData);
        setPinnedPosts(pinnedData);

        if (pinnedData.length > 2 && props.user) {
          setPinnedPostsOverflow(true);
        } else {
          setPinnedPostsOverflow(false);
        }
      };

      loadPosts();
    }
  }, [props.category, props.db]);

  useEffect(() => {
    if (rawPosts) {
      // Filter out undefined or null IDs from rawPinnedPosts
      const pinnedIds = new Set(
        rawPinnedPosts?.filter((p) => p.id).map((p) => p.id)
      );

      // Filter rawPosts, ensuring that we only consider posts with valid IDs
      const filteredPosts = rawPosts.filter(
        (post) => post.id && !pinnedIds.has(post.id)
      );

      // Sort the filtered posts
      filteredPosts.sort(
        dynamicSort(
          newToOld
            ? `-${CMSConfig.datePropertyName || "date"}`
            : CMSConfig.datePropertyName || "date"
        )
      );
      setPosts(filteredPosts);
    }
  }, [rawPosts, rawPinnedPosts]);

  useEffect(() => {
    if (rawPinnedPosts) {
      const sortedPinnedPosts = [...rawPinnedPosts].sort(
        dynamicSort(
          newToOld
            ? `-${CMSConfig.datePropertyName || "date"}`
            : CMSConfig.datePropertyName || "date"
        )
      );
      setPinnedPosts(sortedPinnedPosts);
      if (sortedPinnedPosts.length > 2 && props.user) {
        setPinnedPostsOverflow(true);
      } else {
        setPinnedPostsOverflow(false);
      }
    }
  }, [rawPinnedPosts, props.user]);

  return {
    posts,
    pinnedPosts,
    pinnedPostsOverflow,
  };
};

import React from "react";
import { AuthorSpan } from "./AuthorSpan";
import { CategoryIconsBar } from "./CategoryIconsBar";
import defense from "./geloofsverdediging.svg";
import CMSConfig from "./CMSConfig";
import { Link } from "react-router-dom";
import {
  dateToDutchEasyDate,
  dateToDutchTimeString,
} from "./CMS/logic/HelperFunctions";
import { BiCalendar } from "react-icons/bi";

export const OtherCard = (props) => {
  return (
    <Link
      to={`/${encodeURIComponent(
        props.category.replaceAll(" ", "-")
      )}/${encodeURIComponent(props.post.title.replaceAll(" ", "-"))}`}
    >
      <div className="flex flex-col  group hover:-translate-y-1 transition-all duration-200 cursor-pointer w-80  rounded justify-between">
        <img
          className={`rounded-md group-hover:shadow-inset   h-48 object-cover ${props.imgClassNames}`}
          src={props.post[CMSConfig.postImgVarname || "img"] || defense}
        ></img>
        <div className="absolute h-48 w-80 p-2 flex ">
          {props.post.eventDate && (
            <span className="flex items-center gap-2 left-0 top-0  h-10">
              {" "}
              <span className="bg-white rounded-full text-sm flex h-8 gap-2 items-center p-1 px-2">
                <BiCalendar />{" "}
                {dateToDutchEasyDate(props.post.eventDate.toDate())}
              </span>
              {!props.hideCategories && <CategoryIconsBar />}
            </span>
          )}
        </div>

        <h3 className="font-bold text-2xl group-hover:underline line-clamp-2">
          {props.post.title}
        </h3>
        {!props.hideAuthor && <AuthorSpan />}
        <p className="text-gray-600 line-clamp-4">
          {props.post.clean_content?.replaceAll("&nbsp;", " ")}
        </p>
      </div>
    </Link>
  );
};

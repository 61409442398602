import "./App.css";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import React, { Suspense, lazy, useState, useEffect } from "react";
import { IKContext } from "imagekitio-react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { getFirestore, doc, collection, setDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import "./index.css";

import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { NavBar } from "./NavBar";
import { Home } from "./pages/Home";
import { useAuthState } from "react-firebase-hooks/auth";
import { Poster } from "./Poster";
import { Footer } from "./Footer";

const Dashboard = React.lazy(() => import("./CMS/pages/Dashboard"));
const Editor = React.lazy(() => import("./CMS/pages/Editor"));
const Post = React.lazy(() => import("./pages/Post"));
const Blog = React.lazy(() => import("./CMS/pages/DashboardTabs/Blog"));
const More = React.lazy(() => import("./CMS/pages/More"));

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDTN6y6kOwbqd9cZ87YESZHJ3E9c9SLbUc",
  authDomain: "apologetica-e94dc.firebaseapp.com",
  projectId: "apologetica-e94dc",
  storageBucket: "apologetica-e94dc.appspot.com",
  messagingSenderId: "93248634964",
  appId: "1:93248634964:web:486ccb88a3bcc8782fedc1",
  measurementId: "G-GSZBFFMXLB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

const auth = getAuth();
const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 5001);

function App(props) {
  // Track user with auth state
  const [user, loadingUser, errorUser] = useAuthState(auth);

  const [userData, loadingUserData, errorUserData] = useDocumentData(
    doc(db, "users", user?.uid || "empty")
  );

  // Create user data if it does not exist
  useEffect(() => {
    console.log("new user", user?.email);
    if (!userData && !loadingUserData && user && !loadingUser) {
      setDoc(doc(db, "users", user.uid), {
        displayName: user?.displayName,
        email: user?.email,
        bio: "",
        website: "",
        photoUrl: user?.photoURL,
        rights: [],
        rightRequests: [],
        uid: user?.uid,
      });
    }
  }, [user]);

  const history = useNavigate();

  // Load metadata
  const [metadata, loading, error] = useDocumentData(
    doc(collection(db, "metadata"), "metadata")
  );

  return (
    <MantineProvider>
      <Notifications />
      <IKContext urlEndpoint="https://ik.imagekit.io/cis">
        {" "}
        {/* <HeatMap functions={functions} db={db}> */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {" "}
            <Route path="/poster" element={<Poster />} />
            <Route
              path="/dashboard/:tab?"
              element={
                <>
                  {" "}
                  <NavBar></NavBar>
                  <Dashboard
                    metadata={metadata}
                    db={db}
                    userData={userData}
                    user={user}
                    signInSuccessUrl="dashboard"
                    firebase={app}
                    functions={functions}
                    auth={auth}
                    analytics={analytics}
                    isValidMail={true}
                    buttonStyles="bg-sky-500 hover:bg-sky-600 text-white"
                  />
                </>
              }
            />
            {/* <Route
              path="/editpost/:name"
              element={
                <Editor
                  metadata={metadata}
                  db={db}
                  user={user}
                  isValidMail={true}
                  firebase={app}
                  functions={functions}
                  auth={auth}
                  // For old functions which expect imgs not in imgs/ folder
                  // replaceBucketImgs={"unimix"}
                  analytics={analytics}
                  app={app}
                  maxPostsInSection={20}
                />
              }
            /> */}
            <Route
              path="/meer"
              element={
                <>
                  <NavBar></NavBar>
                  <More
                    metadata={metadata}
                    db={db}
                    user={user}
                    firebase={app}
                    functions={functions}
                    auth={auth}
                    analytics={analytics}
                  />
                </>
              }
            />
            <Route
              path="/:category/:postname"
              element={
                <>
                  <NavBar />
                  <Post
                    metadata={metadata}
                    db={db}
                    user={user}
                    firebase={app}
                    functions={functions}
                    auth={auth}
                    analytics={analytics}
                    buttonStyles="p-1 px-3 rounded-lg bg-gradient-to-br from-purple-600 to-yellow-500 text-white text-md font-bold hover:px-5 transition-all duration-200"
                  />
                </>
              }
            />
            <Route
              path="/:category/"
              element={
                <>
                  <NavBar />
                  <Post
                    metadata={metadata}
                    db={db}
                    user={user}
                    firebase={app}
                    auth={auth}
                    analytics={analytics}
                    buttonStyles="p-1 px-3 rounded-lg bg-gradient-to-br from-purple-600 to-yellow-500 text-white text-md font-bold hover:px-5 transition-all duration-200"
                  />
                </>
              }
            />
            <Route
              path="/"
              element={
                <>
                  <NavBar metadata={metadata} />
                  <Home metadata={metadata} db={db} />
                  <Footer />
                </>
              }
            />
          </Routes>
          {/* Footer */}
          {/* <div className="h-32 w-full bg-secondary flex justify-center p-8">
            <div className="flex-col justify-center text-slate-50 font-bold">
              <p>Geloofsverdediging.nl</p>
            </div>
          </div> */}
        </Suspense>
      </IKContext>
    </MantineProvider>
  );
}

export default App;

import React from "react";
import defense from "./geloofsverdediging.svg";
import kul from "./kul.png";
import { Book, HandMove, Heartbeat, List } from "tabler-icons-react";
import { GiHeartBeats, GiMaterialsScience } from "react-icons/gi";
import { BsPeople, BsSmartwatch } from "react-icons/bs";
import { HiDocument } from "react-icons/hi";

export const Poster = () => {
  return (
    <div className="h-screen w-screen text-gray-700">
      <img src={kul} className="h-16 pt-4 ml-8" />
      <div className="-mt-16 ">
        <div className="w-full flex justify-between h-96">
          <div className="flex w-full   flex-col z-10 justify-around p-8  -mr-52">
            <h1 className="text-4xl mt-8 md:text-6xl lg:text-8xl break-word  w-full  font-bold ">
              Calming in Context
            </h1>
            <h2 className="text-lg md:text-2xl lg:text-3xl font-bold">
              Investigating Attitudes towards and Experiences of Positive Touch
              in promoting Mental and Physical Well-being 
            </h2>
          </div>{" "}
          <img
            src={defense}
            className="w-3/5    object-cover"
            style={{
              clipPath: ` ellipse(${40 / 25 + 90}% ${
                -40 / 10 + 100
              }% at 100% 20%)`,
            }}
          />
        </div>{" "}
        <div className="mt-4 p-8 flex gap-8">
          <div className="w-1/2">
            <h2 className="text-3xl font-bold flex items-center gap-2">
              {" "}
              <Book />
              Background
            </h2>
            <p className="text-primary  mt-2">
              Interpersonal touch can alleviate stress and protect against
              negative health outcomes1. Yet, the beneficial effects of touch
              are context-dependent and research on the effects of type of touch
              and attitudes towards touch is lacking.
            </p>
          </div>
          <div className="w-1/2">
            <h2 className="text-3xl font-bold flex items-center gap-2">
              {" "}
              <List />
              Methods
            </h2>
            <p className="text-primary  mt-2">
              In a population-based, longitudinal study we apply the Touch
              Experiences and Attitudes Questionnaire to investigate the effects
              of attitudes towards and experiences of positive touch on mental
              and physical well-being.
            </p>
            <div className="flex justify-center w-full mt-4 flex-col items-center">
              <BsPeople size={32} />
              <p className="text-secondary font-bold">18-65 jaar</p>
              <div className="flex justify-between  w-full mt-8">
                <div className="flex flex-col items-center text-center w-40 justify-center text-sm font-medium">
                  <HiDocument size={28} />
                  <p className="text-tertiary font-bold">Questionnaries</p>
                </div>
                <div className="flex flex-col items-center text-center w-40 justify-center text-sm font-medium">
                  <BsSmartwatch size={28} />
                  <p className="text-tertiary font-bold">
                    Ambulatory Assessment
                  </p>
                </div>
                <div className="flex flex-col items-center text-center w-40 justify-center text-sm font-medium">
                  <GiHeartBeats size={28} />{" "}
                  <p className="text-tertiary font-bold">Study Visit</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
